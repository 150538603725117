<template>
<v-container>
    <div v-if="loading">
        <v-progress-linear indeterminate color="primary" height="5"></v-progress-linear>

    </div>
    <div v-else>
        <v-row>
            <v-col class="text-center">
                <v-btn @click="showOpenCash()" color="primary" rounded dark class="mb-2 col-3">
                    <v-icon small>{{ icons.mdiPlus }} </v-icon>Abrir Novo Caixa
                </v-btn>
            </v-col>
        </v-row>
        <!-- <v-row no-gutters>
            <v-col class="text-center">
                <v-btn @click="storeOpenLastCash()" color="secondary" rounded dark class="mb-2 col-3">
                    Reabrir Último Caixa
                </v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col class="text-center">
                <v-btn @click="showOldCash()" color="secondary" rounded dark class="mb-2 col-3">
                    Reabrir Caixa Antigo
                </v-btn>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col class="text-center">
                <v-btn color="secondary" @click="showRegisterOldCash()" rounded dark class="mb-2 col-3">
                    Cadastrar Caixa Antigo
                </v-btn>
            </v-col>
        </v-row> -->

        <v-row no-gutters>
            <v-col class="text-center">
                <v-btn @click="showCloseCash()" rounded dark class="mb-2 col-3">
                    Lista de Caixas
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="text-center">
                <v-btn color="success" rounded dark class="mb-2">
                    <v-icon small>{{ icons.mdiPlus }} </v-icon>Fluxo de Caixa
                </v-btn>
            </v-col>
        </v-row>

    </div>

    <ModalOpenCash v-model="showModalOpenCash" />
    <ModalOldCash v-model="showModalOldCash" />
    <!-- <ModalRegisterOldCash v-model="showModalRegisterOldCash" /> -->
</v-container>
</template>

<script>
import {
    mdiMagnify,
    mdiPlus
} from '@mdi/js'

import ModalOpenCash from './_components/ModalOpenCash.vue'
import ModalOldCash from './_components/ModalOldCash.vue'
// import ModalRegisterOldCash from './_components/ModalRegisterOldCash.vue'
import {
    mapActions,
    mapGetters,
    mapMutations
} from 'vuex'

export default {
    components: {
        mdiPlus,
        ModalOldCash,
        mdiMagnify,
        ModalOpenCash,
        // ModalRegisterOldCash
    },
    data: () => ({
        loading: true,
        showModalOpenCash: false,
        showModalOldCash: false,
        showModalRegisterOldCash: false,
        search: '',
        icons: {
            mdiMagnify,
            mdiPlus
        },
        cash: [],
    }),

    created() {

        this.getCash().then((response) => {

            if (Object.keys(response.data).length > 0 ) {
                this.$router.push('cash')
            }
        }).finally(()=>{
            this.loading = false
        })

    },
    watch: {

    },
    computed: {
        // ...mapGetters('cash', ['getCash']),
    },

    methods: {
        ...mapActions('cash', ['openLastCash', 'getCash']),
        // ...mapMutations('cash', ['setCash']),

        storeOpenLastCash() {

            this.openLastCash()
                .then((response) => {
                    // this.setCash(response.data)
                    this.$router.push('/cash')
                }).finally(() => {

                })
        },
        showOpenCash() {
            this.showModalOpenCash = true
        },
        showOldCash() {
            this.showModalOldCash = true
        },
        showRegisterOldCash() {
            this.showModalRegisterOldCash = true
        },

        showCloseCash() {
            this.$router.push('/cash_list')
        },
        extract() {
            let payLoad = {
                product_id: this.product_id,
                dateExtract: this.dateExtract
            }

            this.getExtract(payLoad).then(response => {
                this.product_extract = response.data
            })
        },

        formatMoney(value) {
            if (value != null) {
                return value.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
            }
        },

        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },

    },
}
</script>

<style></style>

