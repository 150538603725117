<template>
<div>
    <v-dialog v-model="value" max-width="500px">

        <v-card>
            <div class="title_body" style="margin-bottom: 20px">
                <div class="bar_top"></div>
                <div class="text_title text-h6">Reabrir Caixa Antigo</div>

                <span class="btn_close">
                    <v-btn icon @click="close()">
                        <v-icon> {{ icons.mdiWindowClose }}
                        </v-icon>
                    </v-btn>
                </span>
            </div>

            <v-card-text>

                <p>
                    <h2><b>Selecione o caixa que deseja reabrir: </b></h2>
                </p>

                <v-row no-gutters>
                    <v-col>
                        <v-switch v-model="lastTenCash" label="Mostrar apenas os últimos 10 caixas"></v-switch>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-select v-model="cashId" :loading="loadingLoadCashs" item-value='id' item-text="text" outlined label="Caixa" :items="oldCashs">
                            rules:
                        </v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-btn color="primary" @click="storeReopenOldCash()" rounded dark class=" float-right ">
                            OK
                        </v-btn>
                        <v-btn color="secondary" @click="close()" rounded dark class=" float-right mr-2 ">
                            FECHAR
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>
    </v-dialog>

</div>
</template>

<script>
import {
    mapActions,
    mapMutations
} from 'vuex'
import {
    mdiWindowClose,
} from '@mdi/js'

export default {
    props: {
        value: {
            type: Boolean
        }
    },
    components: {

    },
    watch: {
        value(val) {
            this.dialog = val;
            if (val) {
                this.loadOldCash()
            }
        },
        lastTenCash() {
            this.loadOldCash()
        }
    },
    data: () => ({
        loadingLoadCashs: false,
        oldCashs: [],
        cashId: null,
        lastTenCash: true,
        icons: {
            mdiWindowClose,
        },
    }),
    created() {

    },
    methods: {
        ...mapActions('cash', ['getOldCash', 'reopenOldCash']),
        ...mapMutations('cash', ['setCash']),
        storeReopenOldCash() {
            this.reopenOldCash(this.cashId)
                .then((response) => {
                    this.setCash(response.data)
                    this.$router.push('/cash');
                }).finally(() => {

                })

        },

        loadOldCash() {
            this.loadingLoadCashs = true
            this.getOldCash(this.lastTenCash).then((response) => {
                this.oldCashs = response.data

            }).finally(() => {
                this.loadingLoadCashs = false
            })
        },
        close() {
            this.$emit('input', false)
            this.cashId = null
        }
    }
}
</script>
